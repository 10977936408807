.copyright-beam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f8f8f8;
  border-top: 1px solid #eaeaea;
  font-size: 0.9rem;
  color: var(--color-text-light);
  margin-top: 2rem;
  width: 100%;
}

.copyright-beam .copyright-text {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.copyright-beam .contact-info {
  display: flex;
  gap: 2rem;
}

.copyright-beam .contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.copyright-beam .contact-item .contact-icon {
  font-size: 1.1rem;
  color: #666;
}

/* Responsive design */
@media (max-width: 768px) {
  .copyright-beam {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
    margin-left: calc(-1 * var(--spacing-sm));
    margin-right: calc(-1 * var(--spacing-sm));
    width: calc(100% + (2 * var(--spacing-sm)));
  }

  .copyright-beam .contact-info {
    flex-direction: column;
    gap: 0.5rem;
    width: 92%;
    margin: 0 auto;
  }

  .copyright-beam .contact-item {
    justify-content: center;
    font-size: var(--font-size-sm);
  }

  .copyright-beam .copyright-text {
    justify-content: center;
    flex-wrap: wrap;
    width: 92%;
    margin: 0 auto;
  }

  .copyright-beam .contact-item .contact-icon {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 480px) {
  .copyright-beam {
    padding: var(--spacing-xs);
  }

  .copyright-beam .contact-item,
  .copyright-beam .copyright-text {
    font-size: var(--font-size-xs);
  }

  .copyright-beam .contact-item .contact-icon {
    font-size: var(--font-size-sm);
  }
}