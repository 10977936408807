.home-page {
  min-height: 100vh;
  padding: var(--spacing-lg);
  max-width: var(--page-width);
  margin: 0 auto;
  position: relative;
}

.section {
  max-width: var(--page-width);
  margin: 0 auto var(--spacing-lg) auto;
}

.flex-container {
  display: flex;
  gap: var(--spacing-lg);
  align-items: flex-start;
}

/* Company Section */
.company-section {
  margin-bottom: var(--spacing-lg);
}

.company-section .section-text,
#about-me .section-text {
    margin-bottom: var(--spacing-md);
}

.image-container {
  width: 33%;
  background-color: var(--color-background-card);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 0;
  /* max-width: 600px; */
  margin: 0 auto;
}

.content-container {
  width: 67%;
  padding: var(--spacing-md);
  background-color: var(--color-background-card);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
}

/* About Me Section */
#about-me {
  margin-bottom: var(--spacing-lg);
}

#about-me .flex-container {
  align-items: flex-start;
  gap: var(--spacing-lg);
}

#about-me .image-container {
  width: 33%;
  height: 100%;
  aspect-ratio: 3/4;
  overflow: hidden;
  border-radius: var(--radius-md);
  /* min-width: 100% ; */
  position: relative;
  display: block;
  padding-bottom: 45%;
}

#about-me .image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% ;
  object-fit: cover;
  object-position: center;
  min-width: 100%;
  /* transform: scale(1.2); */
}

.section-title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-bold);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-sm);
  /* text-align: center; */
}

.section-text {
  color: var(--color-text);
  line-height: 1.6;
}

/* Contact Section */
.contact-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-xl) auto;
}

.contact-buttons-container {
  display: flex;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
}

.contact-button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: var(--color-text-white);
  border-radius: var(--radius-md);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  text-decoration: none;
  font-size: var(--font-size-base);
}

.contact-button:hover {
  background-color: var(--color-primary-dark);
}

.contact-icon {
  font-size: var(--font-size-lg);
}

/* Specializations List */


.specializations-list {
  list-style-type: none;
  padding: 0;
  font-family: var(--font-primary);
}

.specializations-list li {
  margin-bottom: var(--spacing-xs);
  padding-left: var(--spacing-md);
  position: relative;
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--color-text);
}

.specializations-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

/* Image Styling */
.profile-image {
  width: 100%;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  max-width: 400px;
  display: block;
  margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-page {
    padding: var(--spacing-sm);
    width: 100%;
  }

  .flex-container {
    flex-direction: column;
    /* gap: var(--spacing-lg); */
    /* margin-bottom: var(--spacing-lg); */
  }

  .section {
    /* margin-bottom: var(--spacing-xl); */
    width: 92%;
    max-width: var(--mobile-max-width);
    margin: 0 auto var(--spacing-lg) auto !important;
  }

  /* Company Logo Section */

  .company-section .image-container {
    width: 92%;
    max-width: var(--mobile-max-width);
    position: relative;
    padding-top: 100%;
    overflow: hidden;
  }


  .company-section .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
  }

  /* Specializations Section */

  /* About Me Section */
  #about-me .image-container {
    width: 100% !important;
    max-width: var(--mobile-max-width) !important;
    position: relative;
    aspect-ratio: 4/5;
  }

  #about-me .image-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.1);
    width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    object-position: center;
  }

  .content-container {
    width: 92%;
    max-width: var(--mobile-max-width);
    /* margin: 0 auto; */
  }

  /* Contact Section */
  .contact-container {
    width: 100%;
    max-width: var(--mobile-max-width);
    margin-bottom: var(--spacing-lg) auto;  /* Adjust this value as needed */
    /* margin-top: var(--spacing-lg) !important; */
}

  .contact-buttons-container {
    display: flex;
    justify-content: center;
  }

  .contact-button {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .home-page {
    padding: var(--spacing-xs);
  }

  .section-title {
    font-size: var(--font-size-xl);
  }

  .contact-button {
    font-size: var(--font-size-sm);
  }

  .section {
    margin-bottom: var(--spacing-lg);
  }
}