.experience-page {
  padding: var(--spacing-lg);
  max-width: var(--page-width);
  margin: 0 auto;
  position: relative;
}

.page-title {
  font-size: var(--font-size-3xl);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.experience-timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
}

.experience-container {
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: var(--spacing-sm);
}

/* Alternate left/right alignment */
.experience-container.left {
  justify-content: flex-start;
}

.experience-container.right {
  justify-content: flex-end;
}

/* Fluid width: when viewport is large, card stays at 550px; as viewport shrinks, card width decreases (min 350px) */
.experience-card {
  width: clamp(250px, 40%, 550px);
  position: relative;
  padding: var(--spacing-md);
  background-color: var(--color-background-card);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  transition: all 0.3s ease;
  /* No margin auto here so that left/right alignment is preserved */
}

.experience-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.experience-card.expanded {
  background-color: var(--color-background-card);
}

.experience-header {
  margin-bottom: var(--spacing-sm);
}

.role-title {
  font-size: var(--font-size-xl);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-xs);
}

.company-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xs);
}

.company-name {
  font-size: var(--font-size-base);
  font-weight: var(--font-medium);
  /* Fluid width for company name between 200px and 300px */
  width: clamp(200px, 100%, 300px);
  color: var(--color-text);
}

.duration {
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
}

.experience-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
}

.experience-content.show {
  max-height: 1000px;
  transition: max-height 0.5s ease-in;
  opacity: 1;
}

.experience-description {
  color: var(--color-text);
  line-height: 1.6;
  margin-bottom: var(--spacing-sm);
}

.achievements {
  margin-top: var(--spacing-sm);
}

.achievements h3 {
  font-size: var(--font-size-base);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-xs);
}

.achievements ul {
  list-style-type: disc;
  margin-left: var(--spacing-md);
  color: var(--color-text);
}

.achievements li {
  margin-bottom: var(--spacing-xs);
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-sm);
}

.skill-tag {
  background-color: var(--color-background-alt);
  padding: calc(var(--spacing-xs) / 2) var(--spacing-sm);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-xs);
  color: var(--color-text);
}

.description-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.description-list li {
  position: relative;
  padding-left: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
  line-height: 1.6;
}

.description-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

.description-list li.indented {
  padding-left: calc(var(--spacing-md) * 2);
}

.description-list li.indented::before {
  content: "○";
  left: var(--spacing-md);
}

/* Container for the footsteps icons */
/* For left cards, position the container immediately to the right of the card */
.left-to-right {
  position: absolute;
  left: 95%;
  top: 50%;
  transform: translateY(-50%);
}

/* For right cards, position the container immediately to the left of the card */
.right-to-left {
  position: absolute;
  right: 95%;
  top: 50%;
  transform: translateY(-50%);
}

/* Basic styling for the footsteps icons */
.footstep-icon {
  position: absolute;
  color: var(--color-primary-dark);
  font-size: 4.5rem;
}

@media (max-width: 768px) {
  .experience-container {
    justify-content: center !important;
  }
  /* For mobile devices, center the card */
  .experience-card {
    margin: 0 auto;
  }
  
  .company-info {
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--spacing-xs) / 2);
  }

  .connecting-line {
    display: none;
  }

  .experience-page {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .experience-page {
    padding: var(--spacing-xs);
  }

  .role-title {
    font-size: var(--font-size-lg);
  }
}
