.projects-page {
  padding: var(--spacing-lg);
  max-width: var(--page-width);
  margin: 0 auto;
}

.page-title {
  font-size: var(--font-size-3xl);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
}

.project-card {
  border: 2px solid var(--color-border);
  border-radius: var(--radius-md);
  padding: var(--spacing-md);
  transition: all 0.3s ease;
  background-color: var(--color-background-card);
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
  border-color: var(--color-primary);
}

.project-title {
  font-size: var(--font-size-xl);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-xs);
}

.project-metadata {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--spacing-xs) / 2);
  margin-bottom: var(--spacing-sm);
}

.company-name {
  font-weight: var(--font-medium);
}

.project-duration {
  color: var(--color-text-light);
}

.project-description {
  color: var(--color-text);
  line-height: 1.6;
  margin-bottom: var(--spacing-md);
}

.project-tech {
  margin-top: auto;
}

.project-tech h3 {
  font-size: var(--font-size-base);
  color: var(--color-text-dark);
  margin-bottom: var(--spacing-xs);
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.tech-tag {
  background-color: var(--color-background-alt);
  padding: calc(var(--spacing-xs) / 2) var(--spacing-sm);
  border-radius: var(--radius-lg);
  font-size: var(--font-size-xs);
  color: var(--color-text);
}

.description-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.description-list li {
  position: relative;
  padding-left: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
  line-height: 1.6;
}

.description-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

@media (max-width: 768px) {
  .projects-page {
    padding: var(--spacing-sm);
  }

  .project-grid {
    grid-template-columns: 1fr;
    width: 92%;
    margin: 0 auto;
  }

  .project-card {
    width: 100%;
    max-width: var(--mobile-max-width);
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .projects-page {
    padding: var(--spacing-xs);
  }

  .project-title {
    font-size: var(--font-size-lg);
  }
}

/* ExperiencePage.css - Media Queries */
@media (max-width: 768px) {
  .experience-page {
    padding: var(--spacing-sm);
    width: 100%;
    max-width: 100%;
  }

  .experience-container {
    justify-content: center !important;
    width: 92%;
    margin: 0 auto;
  }

  .experience-card {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .experience-page {
    padding: var(--spacing-xs);
  }

  .role-title {
    font-size: var(--font-size-lg);
  }
}

.project-grid {
  gap: var(--spacing-md); /* Reduced gap for better performance */
}

.project-card {
  transform: none; /* Remove hover transform on mobile */
  transition: none; /* Remove transitions for better performance */
}