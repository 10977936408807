.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--color-background);
  box-shadow: var(--shadow-sm);
}

.nav-top {
  border-bottom: 1px solid var(--color-border);
}

.nav-toggle {
  height: var(--nav-height);
  padding: 0 var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--page-width);
  margin: 0 auto;
  position: relative;
}

.left-section {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.company-logo {
  height: 60px;
  width: auto;
  cursor: pointer;
  border-radius: var(--radius-sm);
  transition: opacity 0.2s;
}

.company-logo:hover {
  opacity: 0.8;
}

.toggle-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-base);
  color: var(--color-primary-dark);  
  height: 60px;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-sm);
  transition: background-color 0.2s;
  z-index: 1001;
}

.toggle-button:hover {
  background-color: var(--color-background-alt);
}

.toggle-icon {
  font-size: var(--font-size-xl);
  color: var(--color-primary-dark);  
}

/* Contact and Language Buttons */
.contact-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xs);
  flex-shrink: 0;
  min-width: fit-content;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-md);
  text-decoration: none;
  font-weight: var(--font-medium);
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
  order: 1;
}

.contact-link.email {
  background-color: var(--color-primary);
  color: var(--color-text-white);
}

.contact-link.email:hover {
  background-color: var(--color-primary-dark);
}

.contact-icon {
  font-size: var(--font-size-lg);
  color: white;
}

/* Language Switcher */
.language-switcher {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  order: 2;
}

.language-button {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  border: none;
  background: none;
  cursor: pointer;
  border-radius: var(--radius-md);
  transition: all 0.2s ease;
}

.language-button:hover {
  background-color: var(--color-background-alt);
  transform: scale(1.05);
}

.language-icon {
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  transition: transform 0.2s ease;
}

.language-button:hover .language-icon {
  transform: rotate(15deg);
}

/* Language Menu */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-background);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-sm);
  z-index: 1000;
  min-width: 180px;
  border: 1px solid var(--color-border);
  animation: slideIn 0.2s ease-out;
}

.language-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  margin: calc(var(--spacing-xs) / 2) 0;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: var(--radius-md);
  font-weight: var(--font-medium);
  color: var(--color-text);
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.language-option::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.language-option:hover {
  background-color: var(--color-background-alt);
  padding-left: calc(var(--spacing-md) + 0.25rem);
}

.language-option:hover::before {
  opacity: 1;
}

.language-option.active {
  background-color: var(--color-background-alt);
  color: var(--color-primary);
  padding-left: calc(var(--spacing-md) + 0.25rem);
}

.language-option.active::before {
  opacity: 1;
}

.language-option .country-code {
  margin-left: auto;
  padding-left: var(--spacing-sm);
  color: var(--color-text-light);
  font-size: 0.9em;
  opacity: 0.8;
}

/* Navigation Container */
.nav-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--color-background);
  box-shadow: var(--shadow-md);
  display: none;
  z-index: 1000;
}

.nav-container.open {
  display: block;
}

.nav-content {
  max-width: var(--page-width);
  margin: 0 auto;
  padding: var(--spacing-sm);
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.nav-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-sm);
  background: none;
  border: none;
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
}

.nav-button:hover {
  background-color: var(--color-background-alt);
}

.nav-button.active {
  background-color: var(--color-background-alt);
}

.button-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-xs) / 2);
}

.button-title {
  font-family: var(--font-headers);
  font-weight: var(--font-semibold);
  font-size: var(--font-size-xl);
  color: var(--color-text-dark);
}

.button-description {
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .nav-toggle {
    padding: 0 var(--spacing-sm);
    height: 64px;
  }

  .company-logo {
    height: 50px !important;
    width: auto;
  }

  .contact-buttons {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }

  .contact-link {
    height: 40px;
    padding: 0 var(--spacing-sm);
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    min-height: 40px;
  }

  .contact-link span,
  .contact-icon {
    line-height: 1;
  }

  .language-button {
    height: 40px;
    width: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    min-width: 40px;
  }

  .left-section {
    gap: var(--spacing-xs);
  }

  .toggle-button {
    height: 40px;
  }

  .nav-container.open {
    display: block;
  }

  .button-title {
    font-size: var(--font-size-lg);
  }

  .button-description {
    font-size: var(--font-size-xs);
  }
}

@media screen and (max-width: 480px) {
  .nav-toggle {
    padding: 0 var(--spacing-xs);
  }

  .contact-link span {
    font-size: var(--font-size-xs);
  }

  .company-logo {
    height: 35px;
  }

  .language-button {
    height: 35px;
    width: 35px;
  }
}