/* Base Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap');

/* Force light mode */
:root {
  color-scheme: light !important;
  
  /* Font families */
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  --font-headers: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  
  /* Font weights */
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /* Font sizes */
  --font-size-xs: 0.875rem;
  --font-size-sm: 1rem;
  --font-size-base: 1.125rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-2xl: 2.25rem;
  --font-size-3xl: 2.5rem;

  /* Colors */
  --color-primary: #192A51;
  --color-primary-dark: #283E6E;
  --color-primary-light: #D4B98E;
  
  --color-background: white;
  --color-background-alt: #faf8f5;
  --color-background-card: #ffffff;
  
  --color-text: #374151;
  --color-text-light: #6b7280;
  --color-text-dark: #192A51;
  --color-text-white: #ffffff;
  
  --color-border: #e5e7eb;
  --color-shadow: rgba(0, 0, 0, 0.1);

  --color-accent: #007bff;
  --color-success: #10b981;
  --color-warning: #f59e0b;
  --color-error: #ef4444;

  /* Page general */
  --page-width: 1600px;

  /* Elevation (shadows) */
  --shadow-sm: 0 1px 2px 0 var(--color-shadow);
  --shadow-md: 0 4px 6px -1px var(--color-shadow);
  --shadow-lg: 0 10px 15px -3px var(--color-shadow);

  /* Spacing */
  --nav-height: 80px;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;

  /* Border radius */
  --radius-sm: 0.675rem;
  --radius-md: 0.9rem;
  --radius-lg: 1.2rem;

  /* Mobile specific */
  --mobile-container-width: 100%;
  --mobile-max-width: 720px;
}

/* App Container */
.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
}

/* Global styles */
body {
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  color: var(--color-text) !important;
  padding-top: var(--nav-height);
  background-color: var(--color-background-alt) !important;
  width: 100%;
  overflow-x: hidden;
}

/* Force light mode for all containers */
.image-container,
.content-container,
.nav-wrapper,
.project-card,
.experience-card,
.section-container {
  background-color: var(--color-background-card) !important;
  color: var(--color-text) !important;
}

/* Base Typography */
.page-title {
  font-family: var(--font-headers) ;
  font-weight: var(--font-bold);
  font-size: var(--font-size-3xl);
  color: var(--color-text-dark) !important;
  margin-bottom: var(--spacing-lg);
  text-align: center;
}


.section-title {
  font-family: var(--font-headers) ;
  font-weight: var(--font-semibold);
  font-size: var(--font-size-2xl);
  color: var(--color-text-dark) !important;
  margin-bottom: var(--spacing-sm);
}

/* Text alignment styles */
/* Text alignment styles */
.section-text,
p,
.description-list li,
.experience-description,
.project-description {
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--color-text) !important;
  text-align: left;
  /* Remove or set these properties to 'none' */
  hyphens: none;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  /* Add these properties to prevent unwanted breaks */
  word-wrap: normal;
  word-break: normal;
}

/* Navigation and Buttons */
.nav-button,
.button,
.contact-button,
.contact-link,
.language-option {
  font-family: var(--font-primary);
  font-weight: var(--font-medium);
  font-size: var(--font-size-sm);
}

/* Component Base Styles */
.card {
  background-color: var(--color-background-card);
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-md);
  border: 1px solid var(--color-border);
}

.button {
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: var(--color-text-white);
  border-radius: var(--radius-md);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: var(--color-primary-dark);
}

/* Lists */
.description-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.description-list li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.description-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
  font-size: 1.5rem;
  line-height: 1;
  top: 0.1rem;
}

.description-list li.indented {
  padding-left: 3.5rem;
}

.description-list li.indented::before {
  content: "○";
  font-size: 1rem;
  left: 1.5rem;
  top: 0.25rem;
}

/* Layout */
.main-content {
  flex-grow: 1;
  padding: var(--spacing-lg);
  width: 100%;
  overflow-x: hidden;
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.flex-container {
  display: flex;
  gap: var(--spacing-lg);
  align-items: flex-start;
  width: 100%;
}

/* Ensure the main navigation is properly positioned */
.nav-wrapper {
  z-index: 1000;
  width: 100%;
}

/* Mobile Menu */
.menu-button,
.mobile-nav {
  display: none !important;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  :root {
    --font-size-3xl: 2rem;
    --font-size-2xl: 1.75rem;
    --font-size-xl: 1.75rem;
    --font-size-lg: 1.125rem;
    --font-size-base: 1rem;
  }

  * {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
  }
  
  .main-content {
    will-change: transform;
    transform: translateZ(0);
    padding: var(--spacing-sm);
    width: 100%;
  }
  
  .flex-container {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .project-card,
  .experience-card {
      width: 100% !important;
      margin: 0 !important;
      padding: var(--spacing-sm);
  }
  
  .content-container,
  .image-container,
  .section {
      width: 100% !important;
      padding: var(--spacing-sm);
  }

  .image-container {
    position: relative;
    padding-top: 66.67%;
    overflow: hidden;
  }

  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .section,
  .section-title,
  .section-text,
  .page-title {
    width: 100%;
    padding: 0 var(--spacing-sm);
    margin-left: 0;
    margin-right: 0;
  }

  /* Fix for nested lists */
  .description-list li.indented {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .nav-toggle {
    padding: 0 var(--spacing-xs);
  }

  .contact-link span {
    font-size: var(--font-size-xs);
  }

  .section-title {
    font-size: var(--font-size-lg);
  }

  .main-content {
    padding: var(--spacing-xs);
  }
}